import React, {useState} from 'react';
import '../App.css';
import * as Icon from 'react-bootstrap-icons';
import Video from '../components/video.js';
import Map from '../components/Map.tsx';
import Credenciales from '../components/credenciales.js';
import Header from '../components/header.js';
import Nav from '../components/nav.js';
import Footer from '../components/footer.js';

function App() {
  const [cssData, setShow] = useState(false);
  const [cambiarIcon, checkcambioIcon] = useState(false);

  const clicking = () => {
    setShow(!cssData);
    cambioIcono();
  }
  const cambioIcono = () => {
    checkcambioIcon(!cambiarIcon);
  }

  return (<div className='container' >
    <Header logo="../assets/logo/plaumap.png" url_web='https://plaumap.cl'/>
{/*     <div style={{ display: !cssData ? "unset" : "none" }} ><Video nombre="../assets/video/plaumap.mp4"
        caption="portada"
        replay={true}
        string={
          <div style={{ textAlign: 'center' }}></div>}
      /><Nav/></div> */}
    <div style={{textAlign: 'center'}}>{!cambiarIcon ? 
    
          <Icon.ArrowBarUp alignmentBaseline='central' size={30} color='#04c40f' onClick={clicking}></Icon.ArrowBarUp> :
          <Icon.ArrowBarDown alignmentBaseline='central' size={30} color='#04c40f' onClick={clicking}></Icon.ArrowBarDown>
          
        }</div>
<Map  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Credenciales.mapsKey}`}
              loadingElement={<div />}
              containerElement={<div />}
              mapElement={<div />} />
<Footer color='black' logo="../assets/logo/plaumap.png"/></div>


  );
}

export default App;