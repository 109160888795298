import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import puntos from "../data/points.ts";
import {
    APIProvider,
    Map,
    AdvancedMarker,
    Pin,
    InfoWindow
} from '@vis.gl/react-google-maps';

import credenciales from "./credenciales.js";

export default function Intro() {
    const [userLocation, setUserLocation] = useState(null); // Estado para almacenar la ubicación del usuario

    useEffect(() => {
        // Detecta la ubicación del dispositivo al cargar la página
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error("Error obteniendo la ubicación: ", error);
                    // Si falla, se usa una ubicación predeterminada
                    setUserLocation({
                        lat: -33.1226255628576,
                        lng: -71.5766653932441,
                    });
                }
            );
        } else {
            console.warn("La API de Geolocalización no está soportada por el navegador.");
            setUserLocation({
                lat: -33.1226255628576,
                lng: -71.5766653932441,
            });
        }
    }, []);

    if (!userLocation) {
        // Muestra un mensaje o spinner mientras se obtiene la ubicación
        return <div>Cargando mapa...</div>;
    }

    return (
        <APIProvider apiKey={credenciales.mapsKey}>
            <div style={{ height: '100vh', width: '100%' }}>
                <Map
                    defaultZoom={15}
                    defaultCenter={userLocation} // Centra el mapa en la ubicación del usuario
                    mapId={credenciales.idMap}
                    mapTypeControl={false}
                    maxZoom={18}
                    minZoom={14}
                    fullscreenControl={true}
                    streetViewControl={false}
                >
                    <Markers points={puntos} userLocation={userLocation} />
                </Map>
            </div>
        </APIProvider>
    );
}

type Point = {
    key: string,
    url_web: string,
    url_facebook: string,
    url_instagram: string,
    url_youtube: string,
    location: google.maps.LatLngLiteral,
    ico: number,
    logo: string,
    path: string,
    descripcion: string
};

type Props = { points: Point[], userLocation: google.maps.LatLngLiteral };

const Markers = ({ points, userLocation }: Props) => {
    const [selectedMarkerId, setSelectedMarkerId] = useState(null);

    const handleMarkerClick = (id) => {
        setSelectedMarkerId(id);
    };

    const handleCloseClick = () => {
        setSelectedMarkerId(null);
    };

    const IconoPunto = ({ ico }) => {
        if (ico === 1) {
            return <Pin background="#6990fc" borderColor="#012483" glyphColor="#012483" scale={1} />;
        } else if (ico === 2) {
            return <Pin background="#ff0800" borderColor="#931f1c" glyphColor="#931f1c" scale={0.7} />;
        } else {
            return <Pin background="#5bea00" borderColor="#68b636" glyphColor="#68b636" scale={0.5} />;
        }
    };

    return (
        <>
            {/* Marcador para la ubicación del dispositivo */}
            <AdvancedMarker position={userLocation}>
                <Pin
                    background="#0000ff"
                    borderColor="#000099"
                    glyphColor="#ffffff"
                    scale={1.2}
                    glyph="📍" // Emoji para representar la ubicación del usuario
                />
            </AdvancedMarker>

            {/* Marcadores de puntos */}
            {points.map((point) => (
                <AdvancedMarker
                    position={point.location}
                    key={point.key}
                    onClick={() => handleMarkerClick(point.key)}
                >
                    <IconoPunto ico={point.ico} />
                </AdvancedMarker>
            ))}

            {/* InfoWindow */}
            {selectedMarkerId !== null && (
                <InfoWindow
                    key={selectedMarkerId}
                    position={points.find((point) => point.key === selectedMarkerId)?.location}
                    onCloseClick={handleCloseClick}
                >
                    <div style={{ width: '100%', color: 'black' }}>
                        <img
                            style={{ border: '0', width: '90px' }}
                            className="img-fluid"
                            src={`${points.find((point) => point.key === selectedMarkerId)?.logo}`}
                            alt=""
                        />
                        <p>{points.find((point) => point.key === selectedMarkerId)?.descripcion}</p>
                        <Link
                            to={`/sitios/${points.find((point) => point.key === selectedMarkerId)?.path}`}
                            target="_blank"
                        >
                            <button className="btn btn-sm btn-outline-primary">+Más</button>
                        </Link>
                    </div>
                </InfoWindow>
            )}
        </>
    );
};